// MessageCard.js
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { PREVIEW_PRESET } from '../RemoteConfigKeys.js';

import MessageCard from '../component/MessageCard.jsx';

import getMessageData from '../selector/getMessageData.js';
import getUserData from '../selector/getUserData.js';
import getMeData from '../selector/getMeData.js';
import getMessageCardLink from '../selector/getMessageCardLink.js';
import getMessageThumbnail from '../selector/getMessageThumbnail.js';
import getMessageDataWithVariable, {
  VariableName,
} from '../selector/getMessageDataWithVariable.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import fetchUser from '../action/fetchUser.js';
import { getIsOnMobile } from '../resource/getUserAgent.js';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';

const mapStateToProps = (
  state,
  { id, shouldShowTooltip, isShorts, listPath }
) => {
  if (!id) {
    // We only need placeholder here, so let's skip the other calculation.
    return {};
  }
  const userId = getMessageData(state, id, 'senderId');
  const thumbnailSource = getMessageThumbnail(
    state,
    id,
    '',
    '256x256',
    MediaAssetFormat.JPG,
    'clear'
  );
  const thumbnailSourceBlurred = getMessageThumbnail(
    state,
    id,
    '',
    '256x256',
    MediaAssetFormat.JPG,
    'blurred'
  );
  const isMobile = getIsOnMobile();
  const isAuthed = !!getMeData(state, 'id');
  const meUsername = getMeData(state, 'username');

  return {
    messageId: id,
    userId,
    username: getUserData(state, userId, 'username'),
    originalTitle: getMessageDataWithVariable(
      state,
      id,
      'originalTitle',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    originalCaptionText: getMessageDataWithVariable(
      state,
      id,
      'originalCaptionText',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    thumbnailSource,
    thumbnailSourceBlurred,
    enableTooltip: shouldShowTooltip && isAuthed && !isMobile,
    isAuthed,
    hasPackBadge: getMessageData(state, id, 'hasPackBadge'),
    cardLink: getMessageCardLink(state, id, listPath, isShorts),
    hasVerified: getRemoteConfigData(state, PREVIEW_PRESET) === 'clear',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: data => dispatch(fetchUser(data)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MessageCard)
);
