// ProfileCardItem.js
'use strict';
import { connect } from 'react-redux';

import ProfileCardItem from '../component/ProfileCardItem.jsx';
import getOperationData from '../selector/getOperationData.js';
import getMessageData from '../selector/getMessageData.js';
import getUploadJobStatusI18nKey from '../selector/getUploadJobStatusI18nKey.js';
import getUploadJobFailedReasonI18nKey from '../selector/getUploadJobFailedReasonI18nKey.js';
import getMeData from '../selector/getMeData.js';
import getMessageDataWithVariable, {
  VariableName,
} from '../selector/getMessageDataWithVariable.js';
import getAssetData from '../selector/getAssetData.js';

const mapStateToProps = (state, { messageId, categoryId: category }) => {
  const meUsername = getMeData(state, 'username');
  const uploadJobId = getMessageData(state, messageId, 'uploadJobId');
  const assetIds = getMessageData(state, messageId, 'assetIds');
  const assetId = assetIds?.[0];
  return {
    feedId: getOperationData(state, ['fetchedRenewFeedId', category], 'id'),
    clipSourceId: getMessageData(state, messageId, 'clipSourceId'),
    originalTitle: getMessageDataWithVariable(
      state,
      messageId,
      'originalTitle',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    originalCaptionText: getMessageDataWithVariable(
      state,
      messageId,
      'originalCaptionText',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    uploadJobStatus: getOperationData(
      state,
      ['uploadJob', uploadJobId],
      'status'
    ),
    statusI18nKey: getUploadJobStatusI18nKey(state, uploadJobId),
    reasonI18nKey: getUploadJobFailedReasonI18nKey(state, uploadJobId),
    clipStartMsec: getMessageData(state, messageId, 'clipStartMsec'),
    mediaType: assetId
      ? getAssetData(state, [assetId], 'contentType')
      : getMessageData(state, messageId, 'mediaType'),
    expiresAtUnix: getMessageData(state, messageId, 'expiresAtUnix'),
  };
};

export default connect(mapStateToProps)(ProfileCardItem);
